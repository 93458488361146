.vehicle-modal {
    height: 70vh;
    margin: -1em;
    padding: 1em;
    overflow-y: auto;
}
@media(max-width: 768px) {
    .vehicle-modal {
        height: 70vh;
    }
}