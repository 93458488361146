.vehicle-details.add-new-vehicle {
  border-style: dashed;
  border-width: 2px;
  background-color: #264b941a;
}

.add-new-vehicle h2 {
  margin-top: 10px;
}

.swiper-slide-active .vehicle-details {
  outline: 3.5px solid #779e00;
  border: 0px;
}

.swiper-slide-active .vehicle-details.add-new-vehicle {
  outline-style: dashed;
  background-color: #779e001a;
}

.swiper-slide-active .vehicle-details.add-new-vehicle .vehicle-container h2,
.swiper-slide-active
  .vehicle-details.add-new-vehicle
  .vehicle-container
  .styled-icon {
  color: #779e00;
}

.swiper-slide-active .single-slide {
  border: 3.5px solid #779e00;
  outline: 0px;
}

@media screen and (max-width: 769px) {
  .vehicle-details.add-new-vehicle {
    border-style: dashed !important;
  }

  .swiper-slide-active .vehicle-details {
    border: 3.5px solid #779e00;
    outline: 0px;
  }
}
