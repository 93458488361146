.camera {
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 767px) and (orientation: portrait) {
  .camera {
    object-fit: initial;
  }
}

@media (max-width: 767px) and (orientation: landscape) {
  .camera {
    object-fit: initial;
  }
}
@media (min-width: 768px) and (max-width: 850px) and (orientation: landscape) {
  .camera {
    object-fit: initial;
  }
}

@media (min-width: 767px) and (max-width: 1024px) and (orientation: portrait) {
  .camera {
    object-fit: initial;
  }
}
@media (min-width: 850px) and (max-width: 1024px) and (orientation: landscape) {
  .camera {
    object-fit: initial;
  }
}