@font-face {
  font-family: BrandonText;
  src: url(./fonts/BrandonText-Regular.otf);
}

@font-face {
  font-family: BrandonText-Bold;
  src: url(./fonts/BrandonText-Bold.otf);
}

@font-face {
  font-family: Charles-Wright;
  src: url(./fonts/CharlesWright-Bold.ttf);
  font-weight: bold;
}

@font-face {
  font-family: UKNumberPlate;
  src: url(./fonts/UKNumberPlate.ttf);
}

/* lato regular */

@font-face {
  font-family: 'Lato';
  src: url('./fonts/Lato-Regular.eot');
  src: url('./fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Lato-Regular.woff2') format('woff2'),
    url('./fonts/Lato-Regular.woff') format('woff'),
    url('./fonts/Lato-Regular.ttf') format('truetype'),
    url('./fonts/Lato-Regular.svg#Lato_regularregular') format('svg');
  font-weight: 400;
  font-style: normal;
}

/* Lato italic */

@font-face {
  font-family: 'Lato';
  src: url('./fonts/Lato-Italic.eot');
  src: url('./fonts/Lato-Italic.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Lato-Italic.woff2') format('woff2'),
    url('./fonts/Lato-Italic.woff') format('woff'),
    url('./fonts/Lato-Italic.ttf') format('truetype'),
    url('./fonts/Lato-Italic.svg#Lato_regularitalic') format('svg');
  font-weight: 400;
  font-style: italic;
}

/* Lato bold */

@font-face {
  font-family: 'Lato';
  src: url('./fonts/Lato-Bold.eot');
  src: url('./fonts/Lato-Bold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Lato-Bold.woff2') format('woff2'),
    url('./fonts/Lato-Bold.woff') format('woff'),
    url('./fonts/Lato-Bold.ttf') format('truetype'),
    url('./fonts/Lato-Bold.svg#Latobold') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Founders Grotesk';
  src: url('./fonts/FoundersGrotesk-Bold.woff2') format('woff2'),
    url('./fonts/FoundersGrotesk-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
}

body {
  font-family: 'BrandonText', Arial, sans-serif;
}

#app {
  min-height: 100%;
  min-width: 100%;
}

p,
label {
  line-height: 1.5em;
}

input:disabled {
  background-color: lightgray;
}
