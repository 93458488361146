.StripeElement {
  display: block;
  margin: 10px 0 10px 0;
  min-width: 30px;
  padding: 10px 14px;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border-radius: 4px;
  background: white;
}

.StripeElement--invalid {
  border: 1px solid red;
}
