.customer-account-customer-details {
  text-align: left;
}

.customer-account-customer-details div {
  margin-bottom: 10px;
}

.customer-account-customer-details label {
  font-weight: bold;
}

.customer-account-customer-details p {
  color: black;
  font-weight: 100;
}

.your-details-title {
  font-weight: 800;
  font-size: 18px;
  color: #264b94;
}

.missing-vehicle-message {
  /* font-family: freight-sans-pro, sans-serif; */
  font-family: 'Lato';
  color: red;
  font-size: 12px;
  text-align: center;
}

.customer-account-continue {
  background-color: #779e00 !important;
}

.customer-account-continue:disabled {
  background-color: #26469499 !important;
}

.edit-details-link {
  color: #264b94;
  float: right;
}

.edit-details-link i {
  padding-left: 5px;
}
