.swiper-slide {
  /* display: inline-flex; */
  display: grid;
  align-items: stretch;
  height: auto;
}

/* @media screen and (max-width: 767px) and (min-width: 320px) {
  .swiper-slide {
    display: inline-flex;
    height: auto;
  }
}

@media screen and (min-width: 767px) and (max-width: 900px) {
  .swiper-slide {
    display: inline-flex;
    height: auto;
  }
} */
