.sign-in-message {
  background-color: #f7feeb;
  border-radius: 6px;
  border: 1px solid #6e9341;
  border-left-width: 8px;
  font-size: 14px;
}

.sign-in-message p {
  align-items: center;
  margin: 10px 0;
}

.sign-in-message i {
  color: #6e9341;
  margin-right: 5px;
}
