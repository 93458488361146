@media only screen and (min-width: 450px) and (max-width: 767px) {
  .driving-licence-wrapper {
    width: 75% !important;
    margin: 0 auto;
  }
}

.driving-licence-wrapper {
  margin-top: 15px;
  margin-bottom: 15px;
}

@media only screen and (max-width: 767px) {
  .price-comparison-container {
    margin: auto;
  }
}

.centered-item {
  text-align: left;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
